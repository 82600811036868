import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { App } from '@playq/octopus2-apps';

import { appToolkit, useAppDispatch } from '/store';
import { selectApp as selectAppAction } from '/store/toolkits/app/actions';

import { AppsContent } from './AppsContent';

export interface IAppsSelectionProps {
  isModal: boolean;
  onClose?: () => void;
}

export const AppsSelection: FC<IAppsSelectionProps> = ({ isModal, onClose }) => {
  const selectedAppID = useSelector(appToolkit.selectors.appID);
  const dispatch = useAppDispatch();
  const selectApp = useCallback(
    (app: App) => {
      dispatch(selectAppAction({ app }));
      onClose?.();
    },
    [dispatch, onClose]
  );

  return <AppsContent selectedAppID={selectedAppID} onSelectApp={selectApp} isModal={isModal} />;
};
